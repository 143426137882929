import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M35.9577 5.57456C32.3629 1.97974 27.5833 0 22.4995 0C17.4158 0 12.6361 1.97974 9.04136 5.57456C5.44654 9.16947 3.4668 13.949 3.4668 19.0327C3.4668 29.3169 13.1909 37.8709 18.415 42.4664C19.141 43.105 19.7679 43.6565 20.2674 44.123C20.8932 44.7076 21.6964 44.9999 22.4995 44.9999C23.3027 44.9999 24.1058 44.7076 24.7316 44.123C25.2311 43.6564 25.858 43.105 26.584 42.4664C31.8082 37.8708 41.5322 29.3169 41.5322 19.0327C41.5321 13.949 39.5525 9.16947 35.9577 5.57456ZM24.8429 40.4872C24.101 41.1398 23.4604 41.7035 22.9321 42.1968C22.6895 42.4233 22.3094 42.4234 22.0667 42.1968C21.5386 41.7033 20.8978 41.1398 20.1559 40.4872C15.2446 36.1669 6.10264 28.125 6.10264 19.0328C6.10264 9.9916 13.4581 2.63611 22.4994 2.63611C31.5405 2.63611 38.896 9.9916 38.896 19.0328C38.8961 28.125 29.7542 36.1669 24.8429 40.4872Z"
      fill={color}
    />
    <path
      d="M22.4998 9.92627C17.8749 9.92627 14.1123 13.6888 14.1123 18.3137C14.1123 22.9386 17.8749 26.7011 22.4998 26.7011C27.1247 26.7011 30.8872 22.9386 30.8872 18.3137C30.8872 13.6888 27.1247 9.92627 22.4998 9.92627ZM22.4998 24.065C19.3284 24.065 16.7483 21.4849 16.7483 18.3136C16.7483 15.1423 19.3284 12.5622 22.4998 12.5622C25.6712 12.5622 28.2512 15.1423 28.2512 18.3136C28.2512 21.4849 25.6712 24.065 22.4998 24.065Z"
      fill={color}
    />
  </svg>
);
