import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M25.0835 2.1499H17.9168C17.5213 2.1499 17.2002 2.47097 17.2002 2.86654C17.2002 3.26211 17.5213 3.58318 17.9168 3.58318H25.0835C25.479 3.58318 25.8001 3.26211 25.8001 2.86654C25.8001 2.47097 25.479 2.1499 25.0835 2.1499Z"
      fill={color}
    />
    <path
      d="M30.0993 2.1499H29.3827C28.9871 2.1499 28.666 2.47097 28.666 2.86654C28.666 3.26211 28.9871 3.58318 29.3827 3.58318H30.0993C30.4949 3.58318 30.8159 3.26211 30.8159 2.86654C30.8159 2.47097 30.4949 2.1499 30.0993 2.1499Z"
      fill={color}
    />
    <path
      d="M22.3895 36.55H20.6101C19.52 36.55 18.6328 37.4373 18.6328 38.5273V38.8735C18.6328 39.9635 19.52 40.85 20.6094 40.85H22.3888C23.4789 40.85 24.3661 39.9635 24.3661 38.8735V38.5273C24.3661 37.4373 23.4789 36.55 22.3895 36.55ZM22.9328 38.8735C22.9328 39.173 22.6892 39.4167 22.3896 39.4167H20.6101C20.3098 39.4167 20.0661 39.173 20.0661 38.8735V38.5273C20.0661 38.227 20.3097 37.9833 20.6101 37.9833H22.3888C22.6892 37.9833 22.9328 38.227 22.9328 38.5273V38.8735Z"
      fill={color}
    />
    <path
      d="M31.0931 0H11.9072C10.4788 0 9.31641 1.16243 9.31641 2.59075V40.4093C9.31641 41.8376 10.4788 43 11.9072 43H31.0923C32.5206 43 33.683 41.8376 33.683 40.41V2.59075C33.6831 1.16243 32.5207 0 31.0931 0ZM32.2498 40.4093C32.2498 41.0471 31.7309 41.5666 31.0931 41.5666H11.9072C11.2686 41.5666 10.7498 41.047 10.7498 40.4099V2.59075C10.7498 1.95289 11.2686 1.43336 11.9072 1.43336H31.0923C31.7308 1.43336 32.2497 1.95297 32.2497 2.59075V40.4093H32.2498Z"
      fill={color}
    />
    <path
      d="M32.9673 4.30005H10.034C9.63846 4.30005 9.31738 4.62112 9.31738 5.01669V35.1167C9.31738 35.5123 9.63846 35.8333 10.034 35.8333H32.9674C33.3629 35.8333 33.684 35.5123 33.684 35.1167V5.01669C33.684 4.62112 33.3629 4.30005 32.9673 4.30005ZM32.2507 34.4H10.7507V5.73341H32.2507V34.4Z"
      fill={color}
    />
  </svg>
);
