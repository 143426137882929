import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M40.2576 41.6113C40.4492 41.6113 40.6394 41.5296 40.7716 41.3706C41.0078 41.087 40.9694 40.6658 40.6858 40.4296L30.0304 31.5581C29.7468 31.3219 29.3256 31.3604 29.0894 31.6439C28.8531 31.9275 28.8916 32.3491 29.1752 32.5853L39.8306 41.4568C39.9555 41.5608 40.1069 41.6113 40.2576 41.6113Z"
        fill={color}
      />
      <path
        d="M14.9653 31.5581L4.30995 40.4296C4.02637 40.6658 3.98792 41.087 4.22412 41.3706C4.35596 41.5296 4.54616 41.6113 4.73808 41.6113C4.88879 41.6113 5.0402 41.5608 5.16517 41.4568L15.8205 32.585C16.1041 32.3491 16.1426 31.9275 15.9064 31.6439C15.6705 31.3604 15.2489 31.3219 14.9653 31.5581Z"
        fill={color}
      />
      <path
        d="M44.995 16.4686C44.995 16.2702 44.9068 16.0821 44.7544 15.955L40.0491 12.0374V7.74863C40.0491 4.70748 37.5752 2.23351 34.534 2.23351H28.2736L27.9728 1.98289C24.8005 -0.65966 20.1949 -0.65966 17.0215 1.98289L16.7208 2.23351H15.3698C15.0007 2.23351 14.7017 2.53254 14.7017 2.90161C14.7017 3.27068 15.0007 3.57006 15.3698 3.57006H34.534C36.8381 3.57006 38.7126 5.4446 38.7126 7.74863V12.3471V12.3474V20.2737L27.1172 29.9279C24.4407 32.1578 20.5547 32.1574 17.8767 29.9275L6.28278 20.2737V12.3478C6.28278 12.3474 6.28278 12.3471 6.28278 12.3471V7.74863C6.28278 5.4446 8.15766 3.57006 10.4624 3.57006H12.6967C13.0658 3.57006 13.3652 3.27068 13.3652 2.90161C13.3652 2.53254 13.0658 2.23351 12.6967 2.23351H10.4624C7.42089 2.23351 4.94623 4.70748 4.94623 7.74863V12.0374L0.241012 15.9554C0.0899503 16.081 0.00205993 16.2671 0.000686643 16.4635C0.000686643 16.4656 0 16.4669 0 16.469V40.1413C0 42.821 2.18009 45.0007 4.85972 45.0007H34.7932C35.1623 45.0007 35.4613 44.7017 35.4613 44.3326C35.4613 43.9636 35.1623 43.6645 34.7932 43.6645H4.85972C2.91686 43.6645 1.33655 42.0839 1.33655 40.1413V17.8945L5.18896 21.1025C5.1893 21.1025 5.1893 21.1025 5.1893 21.1028L17.0215 30.9548C18.6087 32.2762 20.5526 32.9367 22.4975 32.9364C24.4417 32.9364 26.3867 32.2759 27.9725 30.9548L39.8033 21.1045C39.8054 21.1028 39.8071 21.1014 39.8088 21.0997L43.6581 17.8948V40.141C43.6581 42.0835 42.0778 43.6642 40.135 43.6642H37.4663C37.0973 43.6642 36.7982 43.9632 36.7982 44.3323C36.7982 44.7013 37.0973 45.0007 37.4663 45.0007H40.135C42.8146 45.0007 44.9947 42.8206 44.9947 40.141V16.4741C44.9947 16.4724 44.995 16.4707 44.995 16.4686ZM25.9791 2.23351H19.0159C21.1798 1.04047 23.8155 1.04047 25.9791 2.23351ZM4.94623 13.7767V19.161L1.71283 16.469L4.94623 13.7767ZM40.0491 19.1606V13.7767L43.2825 16.4686L40.0491 19.1606Z"
        fill={color}
      />
      <path
        d="M32.2981 18.512H12.6968C12.3277 18.512 12.0283 18.811 12.0283 19.1801C12.0283 19.5491 12.3277 19.8485 12.6968 19.8485H32.2981C32.6672 19.8485 32.9665 19.5491 32.9665 19.1801C32.9665 18.811 32.6672 18.512 32.2981 18.512Z"
        fill={color}
      />
      <path
        d="M32.2981 22.3928H12.6968C12.3277 22.3928 12.0283 22.6922 12.0283 23.0613C12.0283 23.4303 12.3277 23.7294 12.6968 23.7294H32.2981C32.6672 23.7294 32.9665 23.4303 32.9665 23.0613C32.9665 22.6922 32.6672 22.3928 32.2981 22.3928Z"
        fill={color}
      />
      <path
        d="M32.2981 10.7498H12.6968C12.3277 10.7498 12.0283 11.0491 12.0283 11.4182C12.0283 11.7869 12.3277 12.0863 12.6968 12.0863H32.2981C32.6672 12.0863 32.9665 11.7869 32.9665 11.4182C32.9665 11.0491 32.6672 10.7498 32.2981 10.7498Z"
        fill={color}
      />
      <path
        d="M32.2988 14.6311H27.3986C27.0295 14.6311 26.7305 14.9301 26.7305 15.2992C26.7305 15.6683 27.0295 15.9673 27.3986 15.9673H32.2988C32.6679 15.9673 32.9669 15.6683 32.9669 15.2992C32.9669 14.9301 32.6679 14.6311 32.2988 14.6311Z"
        fill={color}
      />
      <path
        d="M12.6968 15.9673H25.1704C25.5394 15.9673 25.8385 15.6683 25.8385 15.2992C25.8385 14.9301 25.5394 14.6311 25.1704 14.6311H12.6968C12.3277 14.6311 12.0283 14.9301 12.0283 15.2992C12.0283 15.6683 12.3277 15.9673 12.6968 15.9673Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="45" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
