import React from "react";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import { graphql } from "gatsby";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  borders,
  boxShadow,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import useSiteMetadata from "../components/SiteMetadata";
import Layout from "../components/Layout";
import OuterContainer from "../components/OuterContainer";
import Location from "../components/Location";
import OpenEmail from "../components/OpenEmail";
import SmartPhone from "../components/SmartPhone";

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.lg};
  }
`;

const SectionHeader = styled.h2`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
    margin-top: 0;
  }
`;

const SectionTagline = styled.h3`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes.lg};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes["lg"]} auto;
  }
`;

const CardContainer = styled.div`
  display: flex;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;

const Card = styled.div`
  border-radius: ${borderRadius.md};
  box-shadow: ${boxShadow.lg};
  margin-right: ${sizes.xl};
  padding: ${sizes.lg};

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;

    &:first-of-type {
      margin-bottom: ${sizes.xl};
    }
  }
`;

const CardHeader = styled.h3`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 700;
  font-size: ${fontSizes["2xl"]};
`;
const CardText = styled.p``;

const CtaButton = styled.button`
  background: transparent;
  border-radius: ${borderRadius.md};
  border: 1px solid ${colorAliases.red};
  color: ${colorAliases.red};
  padding: ${sizes.md} ${sizes.xl};
  font-size: ${fontSizes.lg};
  cursor: pointer;
  margin: 0 auto;
  box-shadow: ${boxShadow.md};
`;

const BottomInnerContainer = styled(SectionInnerContainer)`
  padding: ${sizes["3xl"]} 0 ${sizes["7xl"]} 0;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.lg};
  }
`;

const LogoContactContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    display: block;
  }
`;

const LogoContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: ${sizes.sm};
  }
`;

const logoStyles = css`
  width: 50px;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 30px;
    margin-right: ${sizes.md};
  }
`;

const LogoLocation = styled(Location)`
  ${logoStyles}
`;

const LogoEmail = styled(OpenEmail)`
  ${logoStyles}
`;

const LogoPhone = styled(SmartPhone)`
  ${logoStyles}
`;

const LogoContactHeading = styled.h4`
  font-weight: 700;
  margin-bottom: ${sizes.md};
  font-size: ${fontSizes.lg};
`;

export const ContactPageTemplate = ({ location }) => {
  const { title, siteUrl } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <title>{`Kontakt | ${title}`}</title>
        <link rel="canonical" href={siteUrl + location.pathname} />
      </Helmet>
      <OuterContainer>
        <SectionInnerContainer>
          <SectionHeader>Kontakt oss</SectionHeader>
          <SectionTagline>
            Vi hjelper deg gjerne med å komme igang med dflow
          </SectionTagline>
        </SectionInnerContainer>

        <SectionInnerContainer style={{ paddingTop: 0 }}>
          <CardContainer>
            <Card>
              <CardHeader>Chat med en i vårt salgsteam</CardHeader>
              <CardText>
                Hvis du tror dflow kan være noe for deg, ta kontakt for en
                uforpliktende samtale.
              </CardText>
              <CtaButton>Chat med salg</CtaButton>
            </Card>
            <Card>
              <CardHeader>Produkt og kundesupport</CardHeader>
              <CardText>
                Vårt supportteam står parat til å hjelpe deg. Ikke nøl med å ta
                kontakt dersom du lurer på noe.
              </CardText>
              <CtaButton>Chat med support</CtaButton>
            </Card>
          </CardContainer>
        </SectionInnerContainer>

        <BottomInnerContainer>
          <LogoContactContainer>
            <LogoContact>
              <LogoLocation color={colorAliases.turqoise}></LogoLocation>
              <LogoContactHeading>Strandveien 50, Lysaker</LogoContactHeading>
            </LogoContact>

            <LogoContact>
              <LogoEmail color={colorAliases.turqoise}></LogoEmail>
              <LogoContactHeading>Post@dib.no</LogoContactHeading>
            </LogoContact>

            <LogoContact>
              <LogoPhone color={colorAliases.turqoise}></LogoPhone>
              <LogoContactHeading>95407070</LogoContactHeading>
            </LogoContact>
          </LogoContactContainer>
        </BottomInnerContainer>
      </OuterContainer>
    </div>
  );
};

const ContactPage = ({ location }) => {
  return (
    <Layout language="no">
      <ContactPageTemplate location={location} />
    </Layout>
  );
};

export default ContactPage;
